import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import firebase from "src/firebaseConfig";
import { Link, useHistory } from "react-router-dom";

const TheHeaderDropdown = () => {
  const history = useHistory();

  const signout = () => {
    firebase
      .app("admin")
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={"./avatars/avatar7.webp"}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        
          <CDropdownItem tag={Link} to="/profile">
            <CIcon name="cil-user" className="mfe-2" />
            Profile
          </CDropdownItem>
  
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Settings</strong>
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={() => signout()}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
