export const logo = [
  "608 134",
  `
  <title>coreui react pro</title>
  <g>
    <g style="fill:#00a1ff">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 285.71 42.65"><text transform="translate(4.2 15.67)" style="font-size:18px;fill:#343d91;font-family:LucidaHandwriting-Italic, Lucida Handwriting;font-style:italic;letter-spacing:0.002007378472222222em"><tspan xml:space="preserve">American Info Systems  Inc</tspan><tspan style="fill:#f1841c"><tspan x="13.22" y="19">Partner with Confidence</tspan></tspan></text></svg>

    </g>
  </g>
`,
];
