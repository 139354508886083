import React from "react";
import CIcon from "@coreui/icons-react";
import {
  FaHubspot,
  FaGoogleDrive,
  FaChalkboardTeacher,
  FaPlusSquare,
} from "react-icons/fa";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "All Jobs",
    to: "/all-jobs",
    icon: <FaChalkboardTeacher className="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "All Candidates",
    to: "/all-candidates",
    icon: <FaChalkboardTeacher className="c-sidebar-nav-icon" />,
  }, 
  {
    _tag: "CSidebarNavTitle",
    _children: ["Main Pages"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Upload Jobs",
    to: "/add-job",
    icon: <FaHubspot className="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Training Content",
    to: "/add-training-content",
    icon: <FaGoogleDrive className="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavItem",
    name: "Add Candidate",
    to: "/add-candidate",
    icon: <FaPlusSquare className="c-sidebar-nav-icon" />,
  },
];

export default _nav;
