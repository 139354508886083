import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./App.css";
import { Provider } from "react-redux";
import store from "./store";
import firebase from "./firebaseConfig";
import TheHeader from "./containers/TheHeader";
import TheSidebar from "./containers/TheSidebar";
import TheFooter from "./containers/TheFooter";
import Dashboard from "./views/dashboard/Dashboard";
const AddJob = React.lazy(() => import("./views/jobs/AddJob"));
const AllJobs = React.lazy(() => import("./views/dashboard/AllJobs"));
const TrainingContent = React.lazy(() => import("./views/trainingCntnt/TrainingContent"));
const Login = React.lazy(() => import("./views/authentication/Login"));
const Profile = React.lazy(() => import("./views/profile/Profile"));
const CandidatePortal = React.lazy(() => import("./views/candidatePortal/Index"));
const Test = React.lazy(() => import("./views/candidatePortal/Test"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {
  const [user, setUser] = useState(false);

  useEffect(() => {
    firebase
      .app("admin")
      .auth()
      .onAuthStateChanged((user) => {
        if (user?.email === "admin@usinfosys.com") {
          setUser(true);
          // window.location.href = "/dashboard";
        } else {
          setUser(false);
          // alert("You are not authorized to access this panel.");
          // window.location.href = "/";
        }
      });
  }, [user]);

  if (user) {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="c-app c-default-layout">
            <TheSidebar />
            <div className="c-wrapper">
              <TheHeader />
              <div className="c-body">
                <React.Suspense fallback={loading}>
                  <Switch>
                    <Route
                      path="/dashboard"
                      name="Dashboard Page"
                      render={(props) => <Dashboard {...props} />}
                    />
                    <Route
                      path="/add-job"
                      name="Add Job"
                      render={(props) => <AddJob {...props} />}
                    />
                    <Route
                      path="/all-candidates"
                      name="View Candidates"
                      render={(props) => <Test {...props} />}
                    />
                    <Route
                      path="/add-training-content"
                      name="Training Content"
                      render={(props) => <TrainingContent {...props} />}
                    />
                    <Route
                      path="/all-jobs"
                      name="All Jobs"
                      render={(props) => <AllJobs {...props} />}
                    />
                    <Route
                      path="/add-candidate"
                      name="Add Candidate"
                      render={(props) => <CandidatePortal {...props} />}
                    />
                    <Route
                      path="/profile"
                      name="Admin Profile"
                      render={(props) => <Profile {...props} />}
                    />
                  </Switch>
                </React.Suspense>
              </div>
              <TheFooter />
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  } else {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
};

export default App;
