import CIcon from '@coreui/icons-react'
import { CCol, CRow,  CWidgetIcon } from '@coreui/react'
import React from 'react'

export default function Widgets() {
  return (
    <div>
        <CRow>
      <CCol sm={6} lg={3}>
      <CWidgetIcon text="Total Job Posted" header="Count: 0" color="primary">
          <CIcon name={'cilSettings'} size={'xl'}/>
        </CWidgetIcon>
      </CCol>
      <CCol sm={6} lg={3}>
      <CWidgetIcon text="Total Candidate Added" header="Count: 41" color="primary">
          <CIcon name={'cilSettings'} size={'xl'}/>
        </CWidgetIcon>
    
      </CCol>
      <CCol sm={6} lg={3}>
      <CWidgetIcon text="Sample Counter 1" header="Count: 0" color="primary">
          <CIcon name={'cilSettings'} size={'xl'}/>
        </CWidgetIcon>
      </CCol>
      <CCol sm={6} lg={3}>
      <CWidgetIcon text="Sample Counter 2" header="Count: 0" color="primary">
          <CIcon name={'cilSettings'} size={'xl'}/>
        </CWidgetIcon>
      </CCol>
    </CRow>
    </div>
  )
}
