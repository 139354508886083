import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <span className="ml-1">
          &copy; {new Date().getFullYear()} Copyright: American Info Systems
          Inc. All Rights Reserved​.
        </span>
      </div>
      <div className="mfs-auto">
        <a
          href="https://test.usinfosys.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-white"
        >
          <span className="mr-1">Homepage</span>
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
