import React from "react";
import Widgets from "./Widgets";
import Traffic from "./Traffic";
export default function Dashboard() {
  return (
    <div>
      <Widgets />
      <Traffic />
    </div>
  );
}
